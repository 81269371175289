import * as React from 'react'
import { translate, InjectedTranslateProps } from 'react-i18next'
import classnames from 'classnames'
import styled from 'tools/theme'

import PlusIcon from 'atoms/Icons/Plus'
import MinusIcon from 'atoms/Icons/Minus'

import { observer } from 'mobx-react'

export interface IQuantityButtonGroup {
  quantity: number
  max?: number
  min?: number
  onAdd?: React.ReactEventHandler<HTMLButtonElement>
  onRemove?: React.ReactEventHandler<HTMLButtonElement>
  parameterName?: string
  className?: string
}

const Text = styled.span`
  font-size: 14px;
  width: 20px;
`

const Button = styled.button`
  && {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
`

const QuantityButtonGroup: React.SFC<
  IQuantityButtonGroup & InjectedTranslateProps
> = ({ quantity, min = 0, max, onAdd, onRemove, parameterName, t }) => {
  const removeText = t('quantity.option.remove', {
    defaultValue: 'Remove from quantity'
  })

  const addText = t('quantity.option.add', {
    defaultValue: 'Add to quantity'
  })

  return (
    <Wrapper>
      <Button
        data-test="QuantityRemoveButton"
        aria-label={removeText}
        title={removeText}
        type="button"
        className={classnames('btn btn-sm btn-link mr-1 btn-remove', {
          invisible: typeof min === 'number' && min === quantity
          // invisible: (typeof min === 'number' && min === quantity) || parameterName === 'driver'
        })}
        onClick={
          typeof min === 'number' && min === quantity ? undefined : onRemove
        }
      >
        <MinusIcon />
      </Button>
      <Text className="d-inline-block text-center ls-8 font-brand">
        {quantity}
      </Text>
      <Button
        data-test="QuantityAddButton"
        aria-label={addText}
        title={addText}
        type="button"
        className={classnames('btn btn-sm btn-link ml-1 btn-add', {
          invisible: typeof max === 'number' && max === quantity
          // invisible: (typeof max === 'number' && max === quantity) || parameterName === 'driver'
        })}
        onClick={
          typeof max === 'number' && max === quantity ? undefined : onAdd
        }
      >
        <PlusIcon />
      </Button>
    </Wrapper>
  )
}

export default translate()(observer(QuantityButtonGroup))
